import type { FC } from 'react'

import type { BenefitsMultipleTextType } from '@Types/blocks/benefitsMultipleText'
import type { ExtendBlocksType } from '@Types/blocks/blocks'

import { Container, List, ListItem, SubTitle, Title, Wrapper } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BenefitsMultipleText: FC<Props> = ({ data }) => {
  const { texts, title } = data as BenefitsMultipleTextType

  return (
    <Container>
      <Wrapper>
        <Title>{title}</Title>
        <SubTitle size="large">
          Rail.Ninja is a global independent online reservation service agency for train tickets
        </SubTitle>
      </Wrapper>

      <List>
        {texts.map((text, index) => (
          <ListItem dangerouslySetInnerHTML={{ __html: text }} key={index} />
        ))}
      </List>
    </Container>
  )
}
