import BodyText from 'firebird-ui/src/components/BodyText'
import styled from 'styled-components'

export const Container = styled.section`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding-inline-start: 104px;
`

export const Title = styled.h3`
  ${p => p.theme.typography.desktop.heading.large.$value};
  font-weight: 500;
  color: ${p => p.theme.colors.black};
  max-width: 520px;

  ${p => p.theme.mediaQueries.mobile} {
    ${p => p.theme.typography.mobile.heading.large.$value};
  }
`

export const SubTitle = styled(BodyText)`
  color: ${p => p.theme.colors.black};
  max-width: 600px;
`

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 32px;
`

export const ListItem = styled.li`
  position: relative;
  list-style-type: none;

  ${p => p.theme.typography.desktop.body.small.$value};
  color: ${p => p.theme.colors.grey8D};
`
